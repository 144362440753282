.subheader {
    position: relative;
    background-color: #e7e7e7;
    z-index: -3;

    .title {
        width: 50%;
        margin: 0 auto;
        h2 {
            font-size: 16px;
            margin: 40px 30px;
            background-color: #80b241;
            color: white;
            padding: 20px 15px;
            border-radius: 10px;
            width: max-content;
        }
        .background {
            height: 100%;
            position: absolute;
            z-index: -2;
            top: 0;
            left: 10%;
        }
        .subbackground {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 50%;

        }
    }
}