@import "../../themes/generated/variables.base.scss";

.footer {
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  border-top: 20px solid #80b241;
  padding: 20px 0 24px 0;
  width: 100%;
  .footer-text {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin: auto;
    .footer-link {
      color: rgba($base-accent, alpha($base-accent) * 0.7);
      text-align: center;
      text-decoration: none;
      &:hover {
        color: rgba($base-accent, alpha($base-accent) * 0.7);
        text-decoration: underline;
      }
    }

  }

}
