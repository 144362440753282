.container-form {
}

.custom-item {
  position: relative;
  min-height: 30px;
  .product-name {
    display: inline-block;
    padding-left: 50px;
    text-indent: 0;
    line-height: 30px;
    font-size: 15px;
    width: 100%;
  }
}

.dx-dropdowneditor-input-wrapper .custom-item > img {
    padding-left: 8px;
}

.custom-item > img {
  height: 40px;
  position: absolute;
  top: 40%;
  margin-top: -15px;
}

.date-text {
  position: absolute;
  margin: 16px 0 0 12px;
  
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.submit-warning {
  margin: 0;
  margin-bottom: -20px;
  padding: 0 !important;
  font-size: 1.1em !important;
  text-align: center;
}