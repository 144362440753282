@import "../../themes/generated/variables.base.scss";
@import "../../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  padding: 1em;
  width: 60%;
  margin: auto;

  .header-logo {
    margin: 1em;
  }

}
